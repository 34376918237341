.figure-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem auto;
    flex-direction: column;
}
.figure-content{
    text-align: center;
   & h1, p{
        padding-bottom: 0.2rem;
    }
}
.figure-download{
    padding-block: clamp(0.9rem, 5vw, 1.5rem);
}
.accessoriesImg{
    padding-block: clamp(1rem, 5vw, 2.5rem);
    max-width: 60rem;
    width: 100%;
}

.footer-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 clamp(0.5rem, 5vw, 2rem);
    gap: 0.1rem;
    max-width: 70rem;
    margin: 2rem auto;
}
.figbtn{
border: 1px solid #F5F0FE;
}

.log {
    max-width: 25rem;
    margin-right: 2rem;
}

.feactures-box {
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;

  
}

.feature,
.action,
.pricing,
.support {
    max-width: 20rem;
}

.attribution {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 1rem clamp(0.5rem, 5vw, 1rem);
    max-width: 70rem;
    margin: 0 auto;
}

.social-media-icon {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.socio-media{
    display: flex;
}
.socio-media a {
padding-inline: 1rem;
color: #808080;
}
/* 
.react-face,
.react-twi,
.react-inst,
.react-face,
.react-link {
    padding: 0 clamp(0.4rem, 5vw, 0.9rem);
    color: #808080;
    margin-top: 0.3rem;
   
} */


.downloading{
    padding-left: clamp(0.2rem, 5vw, 1rem);
}
.app-download {
    list-style: none;
    text-decoration: none;
    border-radius: 30px;
    padding: 0.7rem 1.5rem;
    display: inline-flex;
    align-content: center;
    margin-inline: 0.5rem;  
}
.app-down{
    margin: 0rem 0 0 0.5rem;
   
}

@media only screen and (max-width: 30rem) {
   .figure-container{
    padding-inline: clamp(0.8rem, 5vw, 1.1rem);
    & h1{
        font-size: var(--fs-500s);
    }
   }
 
   .log{
    
    text-align: center;
    margin: 0 auto;
    & img{
        max-width: 10rem;
        width: 100%;
        text-align: center;
        align-content: center;
        justify-content: center;
        margin: 0 auto;
    }
    & p{
        font-size: var(--fs-200);
    }
   } 
   .feactures-box{
        padding-block: 1rem;
    }
    .attribution {
        flex-direction: column;
            margin: 0 auto;
            text-align: center;
            align-content: center;
            justify-content: center;
           
        }
        .social-media-icon{
        flex-direction: column;
            margin: 0 auto;
            justify-content: center;
           
           
        }
               .downloading{
                padding-top: 0.5rem;
               }
}